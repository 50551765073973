import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';

interface RegisterForm {
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  email: string;
  password: string;
  imageUrl: string;
  dateOfBirth: string;
  city: string;
}

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<RegisterForm>({
    firstName: '',
    lastName: '',
    gender: '',
    phoneNumber: '',
    email: '',
    password: '',
    imageUrl: '',
    dateOfBirth: '',
    city: '',
  });

  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setMessage(null);
    setError(null);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, formData);
      if (response.data.status === 201) {
      setMessage(response.data.message);
        return navigate("/login");
      } else {
        setError(response.data.message);
      }
    } catch (err: any) {
      console.log(err);
      setError(err.response?.data?.message || err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-section">
      <div className="row">
        <div className="col-md-8 col-sm-12 register-background d-none d-sm-block"></div>
        <div className="col-md-4 col-sm-12 p-5">
          <div className="text-center">
            <a href="/">
              <img src={logo} className="img-fluid d-block mx-auto mb-4" alt="iGiTREE logo" style={{ width: '15%' }} />
            </a>
          </div>
          <h5 className="text-center text-primary">Sign Up To iGiTREE</h5>
          <form onSubmit={handleSubmit} className="main-input">
            <div className="row">
              {/* First Name */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-address-card"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} required />
                    <label>First Name</label>
                  </div>
                </div>
              </div>

              {/* Last Name */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-user-tie"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} required />
                    <label>Last Name</label>
                  </div>
                </div>
              </div>

              {/* Gender */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-venus-mars"></i></span>
                  <div className="form-floating">
                    <select className="form-control" name="gender" value={formData.gender} onChange={handleChange} required>
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <label>Gender</label>
                  </div>
                </div>
              </div>

              {/* Phone Number */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-phone"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="Phone Number" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                    <label>Phone Number</label>
                  </div>
                </div>
              </div>

              {/* Email */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-envelope"></i></span>
                  <div className="form-floating">
                    <input type="email" className="form-control" placeholder="Email Address" name="email" value={formData.email} onChange={handleChange} required />
                    <label>Email Address</label>
                  </div>
                </div>
              </div>

              {/* Password */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-lock"></i></span>
                  <div className="form-floating">
                    <input type="password" className="form-control" placeholder="Password" name="password" value={formData.password} onChange={handleChange} required />
                    <label>Password</label>
                  </div>
                </div>
              </div>

              {/* Image URL */}
              {/* <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-image"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="Image URL" name="imageUrl" value={formData.imageUrl} onChange={handleChange} />
                    <label>Image URL</label>
                  </div>
                </div>
              </div> */}

              {/* Date of Birth */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-calendar"></i></span>
                  <div className="form-floating">
                    <input type="date" className="form-control" placeholder="Date of Birth" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} required />
                    <label>Date of Birth</label>
                  </div>
                </div>
              </div>

              {/* City */}
              <div className="col-sm-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa-sharp fa-light fa-city"></i></span>
                  <div className="form-floating">
                    <input type="text" className="form-control" placeholder="City" name="city" value={formData.city} onChange={handleChange} required />
                    <label>City</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 mt-2">
                <div className="d-grid gap-2">
                  <button className="btn btn-primary btn-lg btn-flat" type="submit">{loading ? "Loading" : "Sign Up"}</button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                {message && <div className="server-response success-msg">{message}</div>}
                {error && <div className="bg-danger color-palette p-2">{error}</div>}
              </div>
            </div>
          </form>
          
          {/* Terms and Existing Account Links */}
          <div className="row mt-3">
            <div className="col-md-12 text-center g-font-size-10--xs">
              By clicking the “Sign up” button, you are creating an account and agree to the <a href="/terms-of-use">Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-center">
              Already have an account? <a href="/login" className="text-strong">Log In</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
