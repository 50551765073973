// src/components/Notifications.tsx
import React from 'react';

interface Notification {
  notification_id: string;
  notification_date: string;
  user_id: string;
  notification_main: string;
  notification_type: string;
  notification_read: string;
  notification_code: string;
}

interface NotificationsProps {
  notifications: Notification[];
}

const Notifications: React.FC<NotificationsProps> = ({ notifications }) => {
  const timeago = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    let interval = Math.floor(seconds / 31536000);
    
    if (interval > 1) return `${interval} years ago`;
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return `${interval} months ago`;
    interval = Math.floor(seconds / 86400);
    if (interval > 1) return `${interval} days ago`;
    interval = Math.floor(seconds / 3600);
    if (interval > 1) return `${interval} hours ago`;
    interval = Math.floor(seconds / 60);
    if (interval > 1) return `${interval} minutes ago`;
    return `${seconds} seconds ago`;
  };

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <h4 className="g-font-size-18--xs mb-4">Notifications</h4>
        <ul className="list-group list-group-flush">
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <li className="list-group-item" key={notification.notification_id}>
                <div className="row">
                  <div className="col-md-1 col-sm-2">
                    <span className="float-start g-font-size-18--xs">
                      <i className="fa-sharp fa-light fa-bell"></i>
                    </span>
                  </div>
                  <div className="col-md-8 col-sm-7">
                    {notification.notification_main}
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <span className="float-end g-font-size-12--xs">
                      {timeago(notification.notification_date)}
                    </span>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <div className="alert alert-danger">
              <i className="fa-sharp fa-light fa-triangle-exclamation"></i>
              <strong>No Notifications Available</strong>
              <p>Sorry, no pending orders</p>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Notifications;
