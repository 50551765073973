import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from "../../assets/img/logo.png"
import { services } from './services';
import { useUser } from '../../context/UserContext';

const NavBar: React.FC = () => {
  const { user, setUser, loading } = useUser();

  const location = useLocation();
  
  return (
    <div className="container-fluid nav-bar bg-transparent">
      <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
        <Link to="/" className="navbar-brand d-flex align-items-center text-center">
          <div className="icon-logo p-1 me-2">
            <img
              className="img-fluid"
              src={logo}
              alt="Icon"
              style={{ width: 50, height: 50 }}
            />
          </div>
          <h1 className="m-0 text-primary g-font-size-22--xs">iGiTREE</h1>
        </Link>
        
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto">
            <NavLink to="/" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              Home
            </NavLink>
            <NavLink to="/about" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              About
            </NavLink>
            
 {/* Services Dropdown */}
 <div className="nav-item dropdown">
              <a
                href="#"
                className={`nav-link dropdown-toggle ${location.pathname.includes('service') ? 'active' : ''}`}
                data-bs-toggle="dropdown"
              >
                Services
              </a>
              <div className="dropdown-menu rounded-0 m-0">
                <Link
                  to="/ai-driven-family-discovery"
                  className="dropdown-item"
                >
                  AI Driven Family Discovery
                </Link>
                
                <Link
                  to="/dna-testing-and-matching"
                  className="dropdown-item"
                >
                  DNA Testing & Matching
                </Link>

                <Link
                  to="/family-tree-build"
                  className="dropdown-item"
                >
                  Family Tree Building
                </Link>

                <Link
                  to="/igitree-connect"
                  className="dropdown-item"
                >
                  iGiTREE Connect
                </Link>

              </div>
            </div>
            
            <NavLink to="/user-stories" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              Stories
            </NavLink>
            <NavLink to="/contacts" className={({ isActive }) => `nav-item nav-link ${isActive ? 'active' : ''}`}>
              Contact
            </NavLink>
          </div>
          
          {user ? (
            <Link to="/dashboard" className="btn btn-primary align-items-center btn-flat mb-4">
              <i className="fa-sharp fa-light fa-user me-2"></i>
              {user?.firstName}
            </Link>
          ) : (
            <>
              <Link to="/login" className="btn btn-outline-primary align-items-center btn-flat me-2">
                SIGN IN
              </Link>
              <Link to="/register" className="btn btn-primary px-3 align-items-center btn-flat">
                CREATE ACCOUNT
              </Link>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;


