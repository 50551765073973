import React from 'react';
import aboutImage from '../../assets/img/about.jpg'; // Adjust the path based on your project structure
import NavBar from './NavBar';
import TestimonialCarousel from './Testmonial';
import Footer from './Footer';

const AboutUs: React.FC = () => (
  <section>
  <NavBar />
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-md-5 col-sm-12 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img position-relative overflow-hidden p-2 pe-0">
            <img className="img-fluid w-100" src={aboutImage} alt="About Us" />
          </div>
        </div>
        <div className="col-md-7 col-sm-12 wow fadeIn" data-wow-delay="0.5s">
          <h2 className="mb-4">About Us</h2>

          <h5>Our Vision</h5>
          <p className="mb-4">
            Our vision is a world where every person, regardless of their background, can effortlessly trace their roots,
            reconnect with loved ones, and preserve their unique family narratives. Through innovative technology and a
            commitment to humanitarian causes, we aspire to be the global leader in family unity, DNA discovery, and ancestral
            heritage celebration.
          </p>

          <h5>Our Mission</h5>
          <p className="mb-4">
            At iGiTREE, our mission is to bridge the gaps of time and distance, empowering individuals and communities to
            explore, discover, and celebrate their family heritage. We're dedicated to reuniting families, cultivating a sense
            of belonging, and nurturing the world's most precious connections.
          </p>
        </div>
      </div>
    </div>
  </div>
  <TestimonialCarousel />
  <Footer />
  </section>

);

export default AboutUs;                