import React from 'react';
import tree from '../../../assets/img/services/igitree-connect.jpg';
import NavBar from '../NavBar';
import Footer from '../Footer';

const IgitreeConnect: React.FC = () => (
  <section>
    <NavBar />
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-md-5 col-sm-12 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img position-relative overflow-hidden p-2 pe-0">
            <img className="img-fluid w-100" src={tree} alt="iGiTREE Connect" />
          </div>
        </div>
        <div className="col-md-7 col-sm-12 wow fadeIn" data-wow-delay="0.5s">
          <h2 className="mb-4">iGiTREE Connect</h2>

          <h5>Engage and Reconnect</h5>
          <p className="mb-4">
            Experience seamless communication on iGiTREE's platform. Engage in meaningful conversations, be it through messages or video chats, with potential relatives.
          </p>

          <h5>Forge Stronger Family Connections</h5>
          <p className="mb-4">
            We've streamlined the path to forge and strengthen family connections, making it easier than ever to find and communicate with family members, near or far.
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  </section>
);

export default IgitreeConnect;  