import React from 'react';
import tree from '../../../assets/img/services/family-tree-build.jpg';
import NavBar from '../NavBar';
import Footer from '../Footer';

const FamilyTreeBuilding: React.FC = () => (
  <section>
    <NavBar />
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-md-5 col-sm-12 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img position-relative overflow-hidden p-2 pe-0">
            <img className="img-fluid w-100" src={tree} alt="Family Tree Building" />
          </div>
        </div>
        <div className="col-md-7 col-sm-12 wow fadeIn" data-wow-delay="0.5s">
          <h2 className="mb-4">Family Tree Building</h2>

          <h5>Discover Your Heritage</h5>
          <p className="mb-4">
            As we commemorate Kwibuka30, iGiTREE invites you to uncover your ancestry with ease and simplicity. We focus on building your family tree, allowing you to reconnect with your heritage and loved ones.
          </p>

          <h5>Reconnect and Renew</h5>
          <p className="mb-4">
            Our platform also offers user-friendly DNA testing and matching services, empowering you to connect with potential relatives and explore your family history. With a focus on Remember, Unite, and Renew, we honor the memory of our ancestors, unite communities, and renew hope for the future.
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  </section>

);

export default FamilyTreeBuilding;                   
 