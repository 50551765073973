import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase.config';

async function uploadImage(file: File, userId: string): Promise<string | null> {
    try {
        const storageRef = ref(storage, `boards/${userId}/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    } catch (error) {
        console.error('Error uploading image:', error);
        return null;
    }
}

export default uploadImage;
