import React from 'react';
import tree from '../../../assets/img/services/ai-driven-family-discovery.jpg';
import NavBar from '../NavBar';
import Footer from '../Footer';

const AIDrivenFamilyDiscovery: React.FC = () => (
  <section>
    <NavBar />

  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-md-5 col-sm-12 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img position-relative overflow-hidden p-2 pe-0">
            <img className="img-fluid w-100" src={tree} alt="AI Driven Family Discovery" />
          </div>
        </div>
        <div className="col-md-7 col-sm-12 wow fadeIn" data-wow-delay="0.5s">
          <h2 className="mb-4">AI Driven Family Discovery</h2>

          <h5>Discover Your Roots</h5>
          <p className="mb-4">
            Discover your roots with iGiTREE's AI-Powered Family Matching. Our sophisticated artificial intelligence delves into background-related questions about your birthplace, birth date, and family members. With each answer, our system becomes more adept at unveiling potential relatives, bridging families, and reconnecting lost ties.
          </p>

          <h5>How It Works</h5>
          <p className="mb-4">
            Our AI-driven platform will ask you a series of questions tailored to your personal history, then use this data to find and suggest potential family connections. With iGiTREE’s advanced algorithms, you can reconnect with relatives you never knew you had and build a detailed family tree.
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  </section>
);

export default AIDrivenFamilyDiscovery;                 
 