import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import { Link} from 'react-router-dom';

// import './LandingPage.css';
// import { Helmet } from 'react-helmet'; // for meta tags
// import "../../assets/css/owl.theme.default.min.css";
// import "../../assets/css/owl.carousel.min.css";
// import "../../assets/css/owl.theme.default.min.css";


// Import carousel images
import MemorialMuseumImage from '../../assets/img/memorial-museum.jpg';
import FamilyMeetImage from '../../assets/img/family-meet.jpg';
import RwandaTreeImage from '../../assets/img/rwanda-tree.jpg';
import OldRwandaImage from '../../assets/img/old-rwanda.jpg';
import Discovery from '../../assets/img/services/icon/ai-driven-family-discovery.jpg';
import Matching from '../../assets/img/services/icon/dna-testing-and-matching.jpg';
import tree from '../../assets/img/services/family-tree-build.jpg';
import connect from '../../assets/img/services/igitree-connect.jpg';
import aboutImg from '../../assets/img/about.jpg';
import call from '../../assets/img/call-to-action.jpg';
import TestimonialCarousel from './Testmonial';
import ImageSlider from './ImageSlider';

interface LandingPageProps {
  page: string;
  keywords: string;
  description: string;
  twitter: string;
  services: {
    serviceId: string;
    serviceName: string;
    serviceImage: string;
    serviceDescription: string;
    serviceCode: string;
  }[];
}

const Welcome: React.FC<LandingPageProps> = ({ page, keywords, description, twitter }) => {
  return (
    <div>
      <NavBar />

      <div className="container-xxl bg-white p-0">

        {/* SEO and Metadata */}
        {/* <Helmet>
        <title>iGiTREE: {page}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
        <meta property="og:site_name" content="iGiTREE Rwanda" />
        <meta property="og:image" content="/assets/img/seo/igitree-logo.png" />
        <link rel="canonical" href="https://igitree.rw" />
      </Helmet> */}

        {/* Spinner */}
        <div id="spinner" className="spinner-container">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>

        {/* Header Section */}
        <header className="header bg-white p-0">
          <div className="row g-0 align-items-center">
            <div className="col-md-6 p-5 mt-lg-5">
              <h1>Discover Your Roots with <span className="text-primary">iGiTREE</span></h1>
              <p className="animated fadeIn mb-4 pb-2">
                As we commemorate Kwibuka30, iGiTREE invites you to uncover your ancestry with ease and simplicity. We focus on building your family tree, allowing you to reconnect with your heritage and loved ones. Our platform will also offers user-friendly DNA testing and matching services, empowering you to connect with potential relatives and explore your family history.
              </p>
              <p className="animated fadeIn mb-4 pb-2">

                With a focus on Remember, Unite, and Renew, we honor the memory of our ancestors, unite communities, and renew hope for the future.
              </p>
              <a href="/dashboard" className="btn btn-primary">Get Started Now</a>
            </div>

            <div className="col-md-6 animated fadeIn">
              <ImageSlider />
            </div>
          </div>
        </header>

        {/* Services Section */}
        <section className="container-xxl py-5">
  <div className="container">
    <div
      className="text-center mx-auto mb-5 wow fadeInUp"
      data-wow-delay="0.1s"
      style={{ maxWidth: "600px" }}
    >
      <h1 className="mb-3">What We Do</h1>
    </div>
    <div className="row g-4 text-center">
      {/* Service 1 */}
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <Link to="/ai-driven-family-discovery" className="cat-item d-block">
                <img src={Discovery} alt="Service Icon 1" style={{ width: "100%", padding: "10px", objectFit: "cover" }} />
                <h6>AI Driven Family Discovery</h6>
              </Link>
            </div>

      {/* Service 2 */}
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
        <Link to="/dna-testing-and-matching" className="cat-item d-block">
          <img src={Matching} alt="Service Icon 1" style={{ width: "100%", padding: "10px", objectFit: "cover" }} />
          <h6>DNA Testing And Matching</h6>
          </Link>
      </div>

      {/* Service 3 */}
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
        <Link to="/family-tree-build" className="cat-item d-block">
          <img src={tree} alt="Service Icon 1" style={{ width: "100%", padding: "10px", objectFit: "cover" }} />
          <h6>Family Tree Building</h6>
          </Link>
      </div>
      
      {/* Service 4 */}
      <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.4s">
        <Link to="/igitree-connect" className="cat-item d-block">
          <img src={connect} alt="Service Icon 1" style={{ width: "100%", padding: "10px", objectFit: "cover" }} />
          <h6>iGiTREE Connect</h6>
          </Link>
      </div>

    </div>
  </div>
</section>


        {/* About Section */}
        <section className="about py-5">
          <div className="container">
            <div className="row g-5">
              <div className="col-md-5">
                <img src={aboutImg} className="img-fluid w-100" alt="About" />
              </div>
              <div className="col-md-7">
                <h2>About Us</h2>
                <h5>Our Vision</h5>
                <p>Our vision is a world where every person, regardless of their background, can effortlessly trace their roots, reconnect with loved ones, and preserve their unique family narratives. Through innovative technology and a commitment to humanitarian causes, we aspire to be the global leader in family unity, DNA discovery, and ancestral heritage celebration.</p>
                <h5>Our Mission</h5>
                <p>At iGiTREE, our mission is to bridge the gaps of time and distance, empowering individuals and communities to explore, discover, and celebrate their family heritage. We're dedicated to reuniting families, cultivating a sense of belonging, and nurturing the world's most precious connections.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="cta py-5">
          <div className="container bg-white rounded p-4">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6">
                <img src={call} className="img-fluid rounded" alt="CTA" />
              </div>
              <div className="col-lg-6">
                <h1>Information Matching Solution</h1>
                <p>iGiTREE employs advanced AI matching to help individuals separated by conflict, like during the Genocide against the Tutsi, reunite with relatives. Users input family data, and our AI algorithms efficiently connect them with potential family members. Reflecting Kwibuka30's themes of Remember, Unite, and Renew, this service embodies hope and unity in reuniting families.</p>
                <div className="row">
                  <div className="col-sm-6">
                    <a href="/survey" className="btn btn-primary">Take A Quick Survey</a>
                  </div>
                  <div className="col-sm-6">
                    <a href="/register" className="btn btn-dark">Get Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <TestimonialCarousel />
      <Footer />
    </div>
  );
};

export default Welcome;                         