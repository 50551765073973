import React from 'react';
import tree from '../../../assets/img/services/dna-testing-and-matching.jpg';
import Footer from '../Footer';
import NavBar from '../NavBar';

const DNATestingAndMatching: React.FC = () => (
  <section>
    <NavBar />
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <div className="col-md-5 col-sm-12 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img position-relative overflow-hidden p-2 pe-0">
            <img className="img-fluid w-100" src={tree} alt="DNA Testing And Matching" />
          </div>
        </div>
        <div className="col-md-7 col-sm-12 wow fadeIn" data-wow-delay="0.5s">
          <h2 className="mb-4">DNA Testing And Matching</h2>

          <h5>Revolutionizing Family Discovery</h5>
          <p className="mb-4">
            Dive deep into heritage with our DNA Testing and Matching. Using AI-driven questions tailored to individual backgrounds, we're reuniting families and crafting intricate family trees. With iGiTREE, uncover and share your lineage like never before.
          </p>

          <h5>Uncover Your Lineage</h5>
          <p className="mb-4">
            iGiTREE’s cutting-edge technology will help you trace your ancestry, connect with lost relatives, and create a detailed family tree. Our DNA testing solutions provide accurate results and insights into your genetic history.
          </p>
        </div>
      </div>
    </div>
  </div>
   <Footer />
   </section>
);

export default DNATestingAndMatching;