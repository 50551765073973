import React, { createContext, useState, ReactNode, useContext } from 'react';
import EditFamilyNode from '../components/FamilyNode/EditFamilyNode';

interface EditFamilyNodeContextProps {
  openEditor: (memberId: string) => void;
  closeEditor: () => void;
  setFamilyTreeId: (familyTreeId: string) => void;
//   currentFamilyTreeId: string;
  currentFamilyTreeId: string | null;
}

export const EditFamilyNodeContext = createContext<EditFamilyNodeContextProps | undefined>(undefined);

export const EditFamilyNodeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isEditorOpen, setEditorOpen] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState<string | null>(null);
  const [currentFamilyTreeId, setCurrentFamilyTreeId] = useState<string>('');

  const openEditor = (memberId: string) => {
    setCurrentMemberId(memberId);
    setEditorOpen(true);
  };

  const closeEditor = () => {
    setEditorOpen(false);
    setCurrentMemberId(null);
  };

  const setFamilyTreeId = (familyTreeId: string) => {
    setCurrentFamilyTreeId(familyTreeId);
  };


  return (
    <EditFamilyNodeContext.Provider value={{ openEditor, closeEditor, setFamilyTreeId, currentFamilyTreeId }}>
      {children}
      {isEditorOpen && currentMemberId && (
        <EditFamilyNode
          familyTreeId={currentFamilyTreeId}
          memberId={currentMemberId}
          onClose={closeEditor}
          onSave={closeEditor}
        />
      )}
    </EditFamilyNodeContext.Provider>
  );
};
