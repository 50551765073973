import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import API from '../../api/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ResetPasswordFormProps {
  onResetSuccess?: (userCode: string) => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = () => {
    const [searchParams] = useSearchParams();
    const tokenParam = searchParams.get("token");
    const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState(null);


  function parseJwt(token: any) {
    if (!token) {
      return;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }
  useEffect(() => {
    if (tokenParam) {
      let data = parseJwt(tokenParam);
      setToken(data);
    }
  }, [tokenParam]);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setError(null);
    setSuccess(false);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    setError(null);
    setSuccess(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setError('Both password fields are required.');
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
        if (token) {
     const { uid } = token;
    await API.post('/auth/reset', { password, uid });
      setSuccess(true);
      setPassword('');
      setConfirmPassword('');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
        }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.message || 'An error occurred while resetting your password.');
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="login-section">
      <div className="row">
        <div className="col-md-8 col-sm-12 login-background d-none d-sm-block"></div>
        <div className="col-md-4 col-sm-12 p-5">
          <div className="text-center">
            <a href="/">
              <img src={logo} className="img-fluid d-block mx-auto mb-4" alt="iGiTREE logo" style={{ width: '15%' }} />
            </a>
          </div>
          <h5 className="text-center">Reset Password</h5>

          <form className="main-input" onSubmit={handleSubmit}>
            {success && (
              <div className="alert alert-success">
                Your password has been reset successfully.
              </div>
            )}
            {error && (
              <div className="alert alert-danger">
                {error}
              </div>
            )}

            <div className="row">
              <div className="col-sm-12 col-md-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa fa-lock"></i></span>
                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      id="password"
                      placeholder="New Password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <label htmlFor="password">New Password</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa fa-lock"></i></span>
                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                    />
                    <label htmlFor="confirmPassword">Confirm Password</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 mt-2">
                <div className="d-grid gap-2">
                  <button className="btn btn-primary" type="submit" disabled={loading}>
                    {loading ? 'Resetting...' : 'Reset Password'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
