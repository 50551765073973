import React from 'react';
import boardImageone from '../../assets/img/blog/generational-trauma.jpg';
import boardImagetwo from '../../assets/img/blog/family-bonds.jpg';
import blackWoman from '../../assets/img/blog/lost-relatives.jpg';
import NavBar from './NavBar';
import Footer from './Footer';

// Interface for blog data structure
interface Blog {
  blog_id: string;
  blog_date: string;
  blog_creator: string;
  blog_title: string;
  blog_image: string;
  blog_main: string;
  blog_views: string;
  blog_rating: number;
  blog_comments: string;
  blog_code: string;
}

const UserStories: React.FC = () => {
  // Static blog data for demonstration purposes
  const allBlogs: Blog[] = [
    {
      blog_id: "1",
      blog_date: "2024-11-06",
      blog_creator: "John Doe",
      blog_title: "Exploring Family Trees",
      blog_image: boardImageone,
      blog_main: "",
      blog_views: "120",
      blog_rating: 4.5,
      blog_comments: "10",
      blog_code: "blog1"
    },
    {
      blog_id: "2",
      blog_date: "2024-10-15",
      blog_creator: "Jane Smith",
      blog_title: "DNA Testing Explained",
      blog_image: boardImagetwo,
      blog_main: "",
      blog_views: "200",
      blog_rating: 5,
      blog_comments: "15",
      blog_code: "blog2"
    },
    {
      blog_id: "3",
      blog_date: "2024-09-20",
      blog_creator: "Sam Lee",
      blog_title: "Genealogy and Technology",
      blog_image: blackWoman,
      blog_main: "",
      blog_views: "150",
      blog_rating: 4,
      blog_comments: "8",
      blog_code: "blog3"
    }
  ];

  // Function to render 5-star rating
  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - Math.ceil(rating);

    return (
      <div>
        {[...Array(fullStars)].map((_, i) => (
          <i key={`full-${i}`} className="fa fa-star text-warning"></i>
        ))}
        {halfStar && <i className="fa fa-star-half-alt text-warning"></i>}
        {[...Array(emptyStars)].map((_, i) => (
          <i key={`empty-${i}`} className="fa fa-star text-muted"></i>
        ))}
      </div>
    );
  };

  return (
    <section>
      <NavBar />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-0 gx-5 align-items-end">
            <div className="col-lg-6">
              <div className="text-start mx-auto mb-5 wow slideInLeft" data-wow-delay="0.1s">
                <h2 className="mb-2">User Stories</h2>
              </div>
            </div>
            <div className="col-lg-6 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s">
              {/* You can add something here */}
            </div>
          </div>

          <div className="row g-4">
            {allBlogs.length > 0 ? (
              allBlogs.map((blog) => (
                <div key={blog.blog_id} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="property-item rounded overflow-hidden shadow-sm">
                    <div className="position-relative overflow-hidden">
                      <a href="">
                        <img
                          className="img-fluid rounded-3 shadow-lg"
                          src={blog.blog_image}
                          alt={blog.blog_title}
                          style={{
                            width: '100%',
                            height: '200px', // Set a fixed height for uniformity
                            objectFit: 'cover' // Ensures the image maintains aspect ratio
                          }}
                        />
                      </a>
                      <div className="bg-primary btn-flat text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                        <i className="fa-sharp fa-light fa-eye"></i> {blog.blog_views}
                      </div>
                      <div className="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3 shadow">
                        User Stories
                      </div>
                    </div>
                    <div className="p-4 pb-0">
                      <h5 className="text-primary mb-3">
                        <a href="">
                          {blog.blog_title}
                        </a>
                      </h5>
                      <p>{blog.blog_main}</p>
                    </div>
                    <div className="border-top">
                      <div className="row p-2">
                        <div className="col-md-6 col-sm-6">
                          <span>
                            <i className="fa-sharp fa-light fa-thumbs-up g-font-size-22--xs"></i>
                            {renderStars(blog.blog_rating)}
                          </span>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <span>
                            <i className="fa-sharp fa-light fa-share-from-square g-font-size-22--xs"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
                <div className="alert alert-danger" role="alert">
                  <i className="fa-sharp fa-light fa-triangle-exclamation"></i> No User Stories Available
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default UserStories;
