import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import type { ExtNode } from 'relatives-tree/lib/types';
import css from './FamilyNode.module.css';
import male from './male-avatar.png';
import female from './female-avatar.png';
import NewMemberForm from '../newMemberForm/newMemberForm';
import { useParams } from 'react-router-dom';
import EditFamilyNode from './EditFamilyNode';
import { EditFamilyNodeContext } from '../../context/EditFamilyNodeContext';
import NavBar from '../Welcome/NavBar';

interface CustomNode extends ExtNode {
  firstName: string;
  lastName: string;
  imageUrl: string;
  dateOfBirth: string;
  age: string;
}

interface FamilyNodeProps {
  node: CustomNode;
  isRoot: boolean;
  isHover?: boolean;
  onClick: (id: string) => void;
  onSubClick: (id: string) => void;
  style?: React.CSSProperties;
}



export const FamilyNode = React.memo(
  function FamilyNode({ node, isRoot, isHover, onClick, onSubClick, style }: FamilyNodeProps) {
    const clickHandler = useCallback(() => onClick(node.id), [node.id, onClick]);
    const clickSubHandler = useCallback(() => onSubClick(node.id), [node.id, onSubClick]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isImageOpen, setImageOpen] = useState(false);
    const [isFormOpen, setFormOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [option, setOption] = useState("");
    const [uploadedImage, setUploadedImage] = useState<string | null>(null);
    const [uploadedImageFile, setUploadedImageFile] = useState<File | null>(null);
    const [currentNodeId, setCurentNodeid] = useState<string>("");
    const [selectedMemberId, setSelectedMemberId] = useState<string | null>(null);
    const { board_id } = useParams<{ board_id: string }>();
    const validatedBoardId = board_id ?? '';
    const context = useContext(EditFamilyNodeContext);

    if (!context) {
      throw new Error('EditFamilyNodeContext must be used within an EditFamilyNodeProvider');
    }

    const { openEditor, setFamilyTreeId } = context;
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };

    const toggleImage = () => {
      setImageOpen(!isImageOpen);
    }
    const toggleEdit = () => {
      setIsEditing(!isEditing);
    }

    const closeMenu = () => {
      setMenuOpen(false);
    };

    const openForm = () => {
      setFormOpen(true);
      closeMenu();
    };

    const closeForm = () => {
      setFormOpen(false);
    }

    const HandleAddSpouse = () => {
      setOption("spouse");
      // if (node.children.length === 0) {
        openForm();
      // }
    }

    const handleAddChild = () => {
      setOption("child");
      openForm();
    }

    const handleAddFather = () => {
      setOption("parent");
      openForm();
    }

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setUploadedImageFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setUploadedImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleSubmitImage =async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!uploadedImageFile) {
        console.error("No image file uploaded");
        return;
      }
    
      const formData = new FormData();
      formData.append('image', uploadedImageFile);
      setIsLoading(true);
      try {
        const token = JSON.parse(localStorage.getItem("token") || '""');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/family_tree/${board_id}/updatememberImage/${currentNodeId}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
        },
          body: formData,
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        console.log(data, "Image uploaded successfully");
        toggleImage();
    window.location.reload();
      } catch (error) {
        console.error("Error uploading image:", error);
      }finally{
        setIsLoading(false)
      }
    };
    const handleClose = () => {
      toggleEdit();
      setSelectedMemberId(null);
    };


  const handleSave = () => {
    toggleEdit();
  };

    const handleEdit =(e: any) =>{
      e.stopPropagation();
      openEditor(node.id);
      setFamilyTreeId(validatedBoardId);
      console.log("current NodeId", node);
      toggleEdit();
    }

    return (
      <section>
      <div>
        {isFormOpen && <NewMemberForm onClose={closeForm} node={node} option={option} />}
        {isMenuOpen && <div className={css.overlay} onClick={closeMenu}></div>}
        {isMenuOpen && (
          <div className={css.menu}>
            <span className={css.closeButton} onClick={closeMenu}>✖</span>

            <ul>
              <li key="add-spouse" onClick={HandleAddSpouse}>Add Spouse</li>
              <li key="add-son" onClick={handleAddChild}>Add Child</li>
              <li key="add-father" onClick={handleAddFather}>Add Parent</li>
            </ul>
          </div>
        )}

        <div className={css.root} style={style}>
          <div
            className={classNames(
              css.inner,
              css[node.gender],
              isRoot && css.isRoot,
              isHover && css.isHover,
            )}
            onClick={clickHandler}
          >
            <div className={css.id}>
              <div className={`${css.node} ${node.gender === "male" ? css.male : css.female}`}>
                <i className={`fas fa-pen ${css.editIcon}`} onClick={handleEdit}></i>

                <div className={css.profileContainer}>
                  <img src={node?.imageUrl || (node.gender === "male" ? male : female)} alt={`Profile`} className={css.profilePic} />
                </div>

                <div>
                  <i className={`fas fa-camera ${css.cameraIcon}`} onClick={(e) => {
                    e.stopPropagation();
                    setCurentNodeid(node.id);
                    toggleImage();
                  }}></i>
                </div>

                <div className={css.details}>
                  <span className={css.name}>{node.firstName + " " + node.lastName}</span>
                  <span className={css.birthYear}>{node.dateOfBirth}</span>
                </div>

                <i onClick={(e) => {
                  e.stopPropagation();
                  toggleMenu();
                }} className={`fas fa-plus ${css.addIcon}`}></i>


              </div>


            </div>
          </div>
          {node.hasSubTree && (
            <div
              className={classNames(css.sub, css[node.gender])}
              onClick={clickSubHandler}
            />
          )}
          {/* place for image popup */}

        </div>
        {isImageOpen && (
          <div className={css.overlay}>
            <div className={css.formContainer}>
              <h2>Upload Profile Picture</h2>
              <form onSubmit={handleSubmitImage}>
                <div className={css.profileImageContainer}>
                  <img
                    id="profileImage"
                    src={uploadedImage || (node.gender === 'male' ? male : female)}
                    alt="Profile Preview"
                    className={css.profilePic}
                  />
                </div>
                <div className={css.formGroup}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
                <div className={css.formActions}>
                  <button type="submit">{isLoading ? "Submitting ..." : "Submit"}</button>
                  <button type="button" onClick={toggleImage}>
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
         
      </div>
      </section>
    );
  },
);
