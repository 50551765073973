import React, { useState } from 'react';
import styles from './newMemberForm.module.css';
import type { ExtNode } from 'relatives-tree/lib/types';
import { useParams } from 'react-router-dom';

interface CustomNode extends ExtNode {
  firstName: string;
  lastName: string;
}

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  dateOfBirth: string;
  city: string;
  gender: string;
}

interface NewMemberFormProps {
  onClose: () => void;
  node: CustomNode;
  option: string;
}

const NewMemberForm: React.FC<NewMemberFormProps> = ({ onClose, node, option }) => {
  const { board_id } = useParams<{ board_id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    dateOfBirth: '',
    city: '',
    gender: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let parents = [];
    let payload;
    // if (option === 'child') {
    //   if (node?.id) {
    //     parents.push({ id: node.id, type: 'blood' });
    //   }
    //   if (node?.spouses?.[0]?.id) {
    //     parents.push({ id: node.spouses[0].id, type: 'blood' });
    //   }
    //   if (parents.length !== 2) {
    //     console.error('Both parents must be selected.');
    //     alert('Both parents must be selected to create a child entry.');
    //     return;
    //   }
    // }


    // payload = {
    //   ...formData,
    //   parents: option === 'child' ? [{ id: node.id, type: 'blood' }, {id: node?.spouses[0]?.id, type: "blood"}] : [],
    //   siblings: [],
    //   spouses: option === 'spouse' ? [{ id: node.id, type: 'married' }] : [],
    //   children: [],
    // };
    if(option === "parent"){
      
      payload = {
        ...formData,
        parents:  [] ,
        siblings: [],
        spouses:  [],
        children: [{ id: node.id, type: 'blood', firstName: node.firstName , lastName: node.lastName }],
      };
  
    }

    if(option === "spouse"){
      payload = {
        ...formData,
        parents:  [],
        siblings: [],
        spouses:  [{ id: node.id, type: 'married', firstName: node.firstName , lastName: node.lastName }],
        children: [],
      };
  
    }

    
    if(option === "child"){
      payload = {
        ...formData,
        parents:  [{ id: node.id, type: 'blood', firstName: node.firstName , lastName: node.lastName }] ,
        siblings: [],
        spouses:  [],
        children: [],
      };
  
    }


    try {
      setLoading(true); 
      const token = JSON.parse(localStorage.getItem("token") || '""');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/family_tree/${board_id}/member`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();
     window.location.reload();
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally{
      setLoading(false);
    }
  };

  const formatLabel = (key: string) => {
    return key
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.formContainer}>
        <h2>Personal Information</h2>
        <form onSubmit={handleSubmit}>
          {Object.keys(formData).map((key) => (
            <div className={styles.formGroup} key={key}>
              <label htmlFor={key}>{formatLabel(key)}</label>
              {key === 'gender' ? (
                <select
                  id={key}
                  name={key}
                  required
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              ) : (
                <input
                  type={key === 'dateOfBirth' ? 'date' : key === 'phoneNumber' ? 'tel' : 'text'}
                  id={key}
                  name={key}
                  value={formData[key as keyof FormData]}
                  onChange={handleChange}
                />
              )}
            </div>
          ))}
          <div className={styles.formActions}>
            <button type="submit">{loading ? "Loading ...": "Submit"}</button>
            <button type="button" className={styles.cancelBtn} onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewMemberForm;
