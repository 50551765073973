import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';

const Contact: React.FC = () => (
  <section>
<NavBar />
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-4">
        <div className="col-12">
          <div className="row gy-4">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
              <div className="bg-light rounded p-3">
                <div className="d-flex align-items-center bg-white rounded p-3" style={{ border: '1px dashed rgba(0, 185, 142, .3)' }}>
                  <div className="icon me-3" style={{ width: '45px', height: '45px' }}>
                    <i className="fa fa-map-marker-alt text-primary"></i>
                  </div>
                  <span>1 KN 78 St, Kigali</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="bg-light rounded p-3">
                <div className="d-flex align-items-center bg-white rounded p-3" style={{ border: '1px dashed rgba(0, 185, 142, .3)' }}>
                  <div className="icon me-3" style={{ width: '45px', height: '45px' }}>
                    <i className="fa fa-envelope-open text-primary"></i>
                  </div>
                  <span>info@igitree.rw</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="bg-light rounded p-3">
                <div className="d-flex align-items-center bg-white rounded p-3" style={{ border: '1px dashed rgba(0, 185, 142, .3)' }}>
                  <div className="icon me-3" style={{ width: '45px', height: '45px' }}>
                    <i className="fa fa-phone-alt text-primary"></i>
                  </div>
                  <span>+250 787 961 983</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <iframe
  className="position-relative rounded w-100 h-100"
  src="https://www.google.com/maps/embed?pb=..."
  frameBorder="0"
  style={{ minHeight: '400px', border: 0 }}
  allowFullScreen
  aria-hidden="false"
  tabIndex={0}  // Corrected here
></iframe>

        </div>
        <div className="col-md-6">
          <div className="wow fadeInUp" data-wow-delay="0.5s">
            <h5 className="text-primary mb-4">Send Us A Message</h5>
            <form action="include/submissions/send-message.php" method="POST">
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="name" placeholder="Your Name" />
                    <label htmlFor="name">Your Name</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input type="email" className="form-control" id="email" placeholder="Your Email" />
                    <label htmlFor="email">Your Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input type="text" className="form-control" id="subject" placeholder="Subject" />
                    <label htmlFor="subject">Subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: '150px' }}></textarea>
                    <label htmlFor="message">Message</label>
                  </div>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3 btn-flat" type="submit" name="doInsert" id="doInsert3" value="Submit">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  </section>

);

export default Contact;
                                               