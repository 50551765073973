import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import type { Node, Relation } from 'relatives-tree/lib/types';
import { Relations } from './Relations';
import css from './NodeDetails.module.css';
import male from '../FamilyNode/male-avatar.png';
import female from '../FamilyNode/female-avatar.png';

interface CustomNode extends Node {
  firstName: string;
  lastName: string;
  imageUrl: string;
  dateOfBirth: string;
  occupation?: string;
  email?: string;
  phoneNumber?: string;
  birthDate?: string;
  relationshipStatus?: string;
}

interface CustomRelation extends Relation {
  firstName: string;
  lastName: string;
}

interface NodeDetailsProps {
  node: Readonly<CustomNode>;
  className?: string;
  onSelect: (nodeId: string | undefined) => void;
  onHover: (nodeId: string) => void;
  onClear: () => void;
}

// Helper function to map Relation[] to CustomRelation[]
const mapToCustomRelations = (
  relations: readonly Relation[], 
  defaultFirstName = "Unknown", 
  defaultLastName = "Unknown"
): CustomRelation[] => {
  return relations.map(relation => ({
    firstName: defaultFirstName,
    lastName: defaultLastName,
    ...relation,
  }));
};

export const NodeDetails = memo(function NodeDetails({ node, className, ...props }: NodeDetailsProps) {
  const closeHandler = useCallback(() => props.onSelect(undefined), [props]);

  return (
    <section className={classNames(css.root, className)}>
      <header className={css.header}>
        <h4 className={css.title}>{node.firstName} {node.lastName}</h4>
        <button className={css.close} onClick={closeHandler}>&#10005;</button>
      </header>

      <div className={css.imageContainer}>
        <img src={node.imageUrl || (node.gender === "male" ? male : female)} alt={`${node.firstName} ${node.lastName}`} className={css.image} />
      </div>

      <div className={css.details}>
        <div className={css.detailsItem}>
          <span className={css.detailsLabel}>Gender:</span>
          <span className={css.detailsValue}>{node.gender}</span>
        </div>
        <div className={css.detailsItem}>
          <span className={css.detailsLabel}>DOB:</span>
          <span className={css.detailsValue}>{node.dateOfBirth}</span>
        </div>
        {node.email && (
          <div className={css.detailsItem}>
            <span className={css.detailsLabel}>Email:</span>
            <span className={css.detailsValue}>{node.email}</span>
          </div>
        )}
        {node.phoneNumber && (
          <div className={css.detailsItem}>
            <span className={css.detailsLabel}>Phone:</span>
            <span className={css.detailsValue}>{node.phoneNumber}</span>
          </div>
        )}
        {node.birthDate && (
          <div className={css.detailsItem}>
            <span className={css.detailsLabel}>Birth Date:</span>
            <span className={css.detailsValue}>{node.birthDate}</span>
          </div>
        )}
        {node.relationshipStatus && (
          <div className={css.detailsItem}>
            <span className={css.detailsLabel}>Relationship Status:</span>
            <span className={css.detailsValue}>{node.relationshipStatus}</span>
          </div>
        )}
      </div>

      <h4 className={css.sectionTitle}>Relations</h4>
      <Relations {...props} title="Parents" items={mapToCustomRelations(node.parents || [])} />
      <Relations {...props} title="Children" items={mapToCustomRelations(node.children || [])} />
      <Relations {...props} title="Siblings" items={mapToCustomRelations(node.siblings || [])} />
      <Relations {...props} title="Spouses" items={mapToCustomRelations(node.spouses || [])} />
    </section>
  );
});
