import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';
import { storage } from '../../firebase.config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import uploadImage from '../../helper/uploadImage';
import API from '../../api/api';
import { Error, Success } from '../Form';
import { useNavigate } from 'react-router-dom';

interface CreateTreeProps {
    pageTitle: string;
    activationCode?: string;
}
interface Messages {
    message?: string
  }

const CreateTree: React.FC<CreateTreeProps> = () => {
    const [boardName, setBoardName] = useState('');
    const [boardDescription, setBoardDescription] = useState('');
    const [boardImage, setBoardImage] = useState<File | null>(null);
    const [errors, setError] = useState<Messages>({});
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<Messages>({});
    const { user } = useUser();
    const navigate = useNavigate();
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setBoardImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e: React.MouseEvent) => {
        e.preventDefault();
        setError({});
        setSuccess({});
        setIsLoading(true);
        if (!user) {
            alert('User not logged in');
            setIsLoading(false);
            return;
        }

        let imageUrl = null;
        if (boardImage) {
            imageUrl = await uploadImage(boardImage, user.id);
            if (!imageUrl) {
                alert('Failed to upload image.');
                setIsLoading(false);
                return;
            }
        }

        const payload = {
            name: boardName,
            description: boardDescription,
            imageUrl,
        };
        try {
            const token = JSON.parse(localStorage.getItem("token") || '""');
            if(token){
                await API.post('/family_tree', payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                });
                setSuccess({
                    message: 'Family tree created successfully',
                  });
                setBoardName('');
                setBoardDescription('');
                setBoardImage(null);
                navigate("/dashboard");
            }
        
        } catch (error: any) {
            setError({
                message: error.response?.data?.message || 'Please try again',
              });
           
        }finally{
            setIsLoading(false);
        }
    };

    return (
        <div className="login-section">
            <div className="row">
                <div className="col-md-5 col-sm-12 p-5">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-12">
                            <form id="regForm" encType="multipart/form-data">
                                <div>
                                    <h4 className="g-font-size-18--md mb-8">Name your tree</h4>
                                    <div className="input-group mt-2">
                                        <span className="input-group-text">
                                            <i className="fa-sharp fa-light fa-list-tree"></i>
                                        </span>
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Family"
                                                value={boardName}
                                                onChange={(e) => setBoardName(e.target.value)}
                                            />
                                            <label>Family Tree Name</label>
                                        </div>
                                    </div>
                                </div>

                                <div >
                                    <h3 className="g-font-size-18--md mt-5 mb-3">Description</h3>
                                    <div className="input-group mt-2">
                                        <span className="input-group-text">
                                            <i className="fa-sharp fa-light fa-pencil-alt"></i>
                                        </span>
                                        <div className="form-floating">
                                            <textarea
                                                className="form-control"
                                                placeholder="Tree Description"
                                                value={boardDescription}
                                                onChange={(e) => setBoardDescription(e.target.value)}
                                            />
                                            <label>Tree Description</label>
                                        </div>
                                    </div>
                                </div>

                                <div >
                                    <h3 className="g-font-size-18--md mt-5 mb-3">Family Image</h3>
                                    <div className="input-group mt-2">
                                        <span className="input-group-text">
                                            <i className="fa fa-image"></i>
                                        </span>
                                        <div className="form-floating">
                                            <input
                                                type="file"
                                                className="form-control"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button type="button" onClick={handleSubmit} className="btn btn-primary mt-3">
                                   {isLoading ? "Loading ..." : "Submit"} 
                                </button>
                            </form>
                            {errors?.message && <Error>{errors.message}</Error>}
                            {success?.message && <Success>{success.message}</Success>}
                        </div>
                    </div>
                </div>
                <div className="col-md-7 col-sm-12 tree-background d-none d-sm-block"></div>
            </div>
        </div>
    );
};

export default CreateTree;
