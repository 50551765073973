import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import type { Node, ExtNode } from 'relatives-tree/lib/types';
import treePackage from 'relatives-tree/package.json';
import ReactFamilyTree from 'react-family-tree';
import { PinchZoomPan } from '../PinchZoomPan/PinchZoomPan';
import { FamilyNode } from '../FamilyNode/FamilyNode';
import { NodeDetails } from '../NodeDetails/NodeDetails';
import { NODE_WIDTH, NODE_HEIGHT, URL_LABEL } from '../const';
import { getNodeStyle } from './utils';

import css from './App.module.css';
import { useParams } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import NewMemberForm from '../newMemberForm/newMemberForm';
import { EditFamilyNodeContext } from '../../context/EditFamilyNodeContext';
interface CustomNode extends ExtNode {
  firstName: string;
  lastName: string;
  imageUrl: string;
  age: string;
}
// NewMemberForm.tsx
interface NewMemberFormProps {
  onClose: () => void;
  option: string;
  node: CustomNode | null; // Allow null for node
}

interface CustomNode extends Node {
  firstName: string;
  lastName: string;
  imageUrl: string;
  dateOfBirth: string;
}
export default React.memo(function App() {
  const context = useContext(EditFamilyNodeContext);
  const { board_id } = useParams<{ board_id: string }>();
  const [nodes, setNodes] = useState<readonly Readonly<CustomNode>[]>([]);
  const [rootId, setRootId] = useState<string>('');
  const [selectId, setSelectId] = useState<string>();
  const [hoverId, setHoverId] = useState<string>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentService, setCurrentservice] = useState<string>("");
  const [formProps, setFormProps] = useState<{ option: string; node: CustomNode | null }>({
    option: "",
    node: null,
  });

  if (!context) {
    throw new Error('EditFamilyNodeContext must be used within an EditFamilyNodeProvider');
  }

  const { openEditor } = context;
  const openForm = (option: any, node: any) => {
    setFormProps({ option, node });
    setFormOpen(true);
  };

  const closeForm = () => {
    setFormOpen(false);
  };
  const [isFormOpen, setFormOpen] = useState(true);

  const { user } = useUser();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    imageUrl: '',
    phoneNumber: '',
    email: '',
    dateOfBirth: '',
    city: '',
    gender: '',
    parents: [],
    siblings: [],
    spouses: [],
    children: [],
  });
  useEffect(() => {
    setLoading(true); 
    if (user) {
      const url = `${process.env.REACT_APP_BASE_URL}/family_tree/${board_id}/member`;
      const token = JSON.parse(localStorage.getItem("token") || '""'); // Retrieve the token

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
        },
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (Array.isArray(data.members)) {
            const customNodes = data.members.map((node: any) => ({
              ...node,
              firstName: node.firstName || 'Unknown',
              lastName: node.lastName || 'Unknown',
            }));
            setNodes(customNodes);
            setRootId(customNodes[0]?.id);
          }
        })
        .catch((error) => {
          console.error("Error fetching members:", error);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [user]);


  const resetRootHandler = useCallback(() => setRootId(nodes[0]?.id || ''), [nodes]);

  const selected = useMemo(
    () => nodes.find((item) => item.id === selectId),
    [nodes, selectId]
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  // Handle form submission
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const token = JSON.parse(localStorage.getItem("token") || '""');
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/family_tree/${board_id}/member`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      const data = await response.json();
      console.log(data);
      window.location.reload();
      // setShowForm(false);
      // setNodes((prevNodes) => [...prevNodes, data]);

    } catch (error) {
      console.log('Error submitting form:', error);
    }
    setShowForm(false);
  };

  return (
    <div className={css.root}>
      {loading ? <div className={css.loader}>Loading...</div>: nodes.length === 0 ?  <div className={css.emptyMessage}>
          <p>
            It looks like there's no one in the family tree yet. You can start by adding yourself to the family tree!
          </p>
          <button onClick={() => setShowForm(true)}>Add yourself</button>
        </div> : (
        <>
          <PinchZoomPan min={0.5} max={2.5} captureWheel className={css.wrapper}>
            <ReactFamilyTree
              nodes={nodes}
              rootId={rootId}
              width={NODE_WIDTH}
              height={NODE_HEIGHT}
              className={css.tree}
              renderNode={(node: ExtNode) => (
                <FamilyNode
                  key={node.id}
                  node={node as CustomNode}
                  isRoot={node.id === rootId}
                  isHover={node.id === hoverId}
                  onClick={setSelectId}
                  onSubClick={setRootId}
                  style={getNodeStyle(node)}
                />
              )}
            />
          </PinchZoomPan>

          {rootId !== nodes[0]?.id && (
            <button className={css.reset} onClick={resetRootHandler}>
              Reset
            </button>
          )}

          {selected && (
            <NodeDetails
              node={selected}
              className={css.details}
              onSelect={setSelectId}
              onHover={setHoverId}
              onClear={() => setHoverId(undefined)}
            />
          )}
        </>
      ) }
      {showForm && (
        <div className={css.popup}>
          <form onSubmit={handleFormSubmit}>
            <h2>Add Yourself </h2>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="imageUrl"
              placeholder="Image URL"
              value={formData.imageUrl}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="date"
              name="dateOfBirth"
              placeholder="Date of Birth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
            />
            <select
              name="gender"
              value={formData.gender}
              required
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="male" >Male</option>
              <option value="female">Female</option>
            </select>
            <button type="submit">{loading ? "Loading ...": "Submit"}</button>
            <button type="button" onClick={() => setShowForm(false)}>
              Cancel
            </button>
          </form>
        </div>
      )}

    </div>
  );
});
