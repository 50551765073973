import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import male from "../FamilyNode/male-avatar.png";
import female from "../FamilyNode/female-avatar.png";

// Interface for testimonials
interface Testimonial {
    id: number;
    profession: string;
    description: string;
    testimonial_date: string;
    testimonial_id: number;
    testimonial_main: string;
    testimonial_rating: string;
    testimonial_status: number;
    name: string;
    image: string;
}

const testimonials: Testimonial[] = [
    {
        testimonial_date: "2024-04-08 04:43:20",
        testimonial_id: 1,
        testimonial_main: "iGiTREE has been a beacon of hope in my life. As a Rwandan refugee, I had lost all contact with my family. Thanks to their platform, I found my siblings after 15 years of separation. I can't express my gratitude enough!",
        testimonial_rating: "4.50",
        testimonial_status: 1,
        name: "Lawrence",
        id: 0,
        image: female,
        profession: "",
        description: ""
    },
    {
        testimonial_date: "2024-04-08 04:43:20",
        testimonial_id: 3,
        testimonial_main: "Being a survivor of the Rwanda Genocide Against the Tutsi, I had little hope of ever finding my lost family members. iGiTREE's mission to reunite families became my beacon of light, and I am eternally grateful for their unwavering support.",
        testimonial_rating: "4.20",
        testimonial_status: 1,
        name: "Fabialla Umwali",
        id: 0,
        image: female,
        profession: "",
        description: ""
    },
    {
        testimonial_date: "2024-04-02 06:34:39",
        testimonial_id: 4,
        testimonial_main: "iGiTREE's mission to promote family connectivity goes beyond borders. As an immigrant in a new land, their platform helped me build bridges to my roots, ensuring my children know where they come from. It's a gift that keeps giving.",
        testimonial_rating: "4.70",
        testimonial_status: 1,
        name: "Lawlence Gumbo",
        id: 0,
        image: male,
        profession: "",
        description: ""
    },
];

const TestimonialCarousel: React.FC = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1376,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="testimonial-area mt-3">
            <div className="container">
                <div className="section-title text-center p-2">
                    <h2 style={{ marginBottom: "30px" }}><span>What Clients Say About Us!</span></h2>
                </div>
                <Slider {...settings} className="testimonial-carousel ">
                    {testimonials.map((testimonial) => (
                        <div className="testimonial-item p-1">
                            <div className="bg-white border rounded p-4">

                                <p>{testimonial.testimonial_main}</p>

                                <div className="d-flex align-items-center">
                                    <img src={testimonial.image} className="img-fluid img-circle shadow" alt="<?php echo getImagePath('profile', $testImage); ?>" style={{ "width": "45px", "height": "45px" }} />
                                    <div className="ps-3">
                                        <h6 className="fw-bold mb-1">{testimonial.name}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default TestimonialCarousel;
