import axios from "axios";

// Create an Axios instance
const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default API;
