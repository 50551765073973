import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../assets/img/logo.png';
import API from '../../api/api';

interface ForgetPasswordFormProps {
  onResetSuccess?: (userCode: string) => void;
}

const ForgetPasswordForm: React.FC<ForgetPasswordFormProps> = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(null);
    setSuccess(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setError('Email is required');
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('Invalid email format');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await API.post('/auth/forget', { email });
      setSuccess(true);
      setEmail('');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(error.response.data.message || 'Email not found or an error occurred while processing your request.');
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-section">
      <div className="row">
        <div className="col-md-8 col-sm-12 login-background d-none d-sm-block"></div>
        <div className="col-md-4 col-sm-12 p-5">
          <div className="text-center">
            <a href="/">
              <img src={logo} className="img-fluid d-block mx-auto mb-4" alt="iGiTREE logo" style={{ width: '15%' }} />
            </a>
          </div>
          <h5 className="text-center">Forgot Password</h5>

          <form className="main-input" onSubmit={handleSubmit}>
            {success && (
              <div className="alert alert-success">
                Password reset instructions have been sent to your email.
              </div>
            )}
            {error && (
              <div className="alert alert-danger">
                {error}
              </div>
            )}

            <div className="row">
              <div className="col-sm-12 col-md-12 mt-2">
                <div className="input-group">
                  <span className="input-group-text"><i className="fa fa-envelope"></i></span>
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="email">Email Address</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 mt-2">
                <div className="d-grid gap-2">
                  <button className="btn btn-primary" type="submit" disabled={loading}>
                    {loading ? 'Sending...' : 'Reset Password'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordForm;
