import React, { useState } from "react";
import logo from '../../assets/img/logo.png';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase.config";
import { Error } from "../Form";
import { useNavigate } from "react-router-dom";

interface Messages {
  message?: string
}


const SignIn: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Messages>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError({});
  const {email, password} =formData;
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem("token", JSON.stringify(await user.getIdToken()));
      window.location.href = "/dashboard";
    } catch (error: any) {
      console.error(error.message);
      localStorage.removeItem("token");
      setLoading(false);

      if (error.code == "auth/invalid-credential") {
        setError({
          message: "Login failed: Your email or password is incorrect",
        });
      } else if (error.code == "auth/user-disabled") {
        setError({
          message:
            "Login failed: Your account has been disabled. Please contact if you think this was a mistake",
        });
      } else {
        setError({
          message: "Something went wrong",
        });
      }
    }
  };

  return (
    <div className="login-section">
      <div className="row">
        <div className="col-md-8 col-sm-12 login-background d-none d-sm-block">
        </div>
        <div className="col-md-4 col-sm-12 p-5">
          <div className="text-center mb-4">
            <a href="/">
              <img
                src={logo}
                alt="iGiTREE logo"
                className="img-fluid d-block mx-auto"
                style={{ width: "15%" }}
              />
            </a>
          </div>
          <h5 className="text-center">Login To iGiTREE</h5>

          <form onSubmit={handleSubmit} className="main-input">
            <div className="input-group mt-2">
              <span className="input-group-text">
                <i className="fa-sharp fa-light fa-envelope"></i>
              </span>
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  required
                />
                <label>Email Address</label>
              </div>
            </div>

            <div className="input-group mt-2">
              <span className="input-group-text">
                <i className="fa-sharp fa-light fa-lock"></i>
              </span>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                  required
                />
                <label>Password</label>
              </div>
            </div>

            <div className="d-grid mt-4">
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {loading ? "Logging In..." : "Log In"}
              </button>
            </div>
          </form>
          {error?.message && <Error>{error.message}</Error>}
          <div className="text-center mt-3">
            <a href="/forgot" className="text-strong">
              I forgot my password
            </a>
          </div>
          <div className="text-center mt-2">
            Don't you have an account?{" "}
            <a href="/register" className="text-strong">
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
