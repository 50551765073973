import App from './components/App/App';
import './index.css';
import './assets/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/mine.css';
import './assets/css/global/global.css';
import './assets/css/all.css';
import './assets/css/sharp-regular.css';
import './assets/css/sharp-solid.css'

import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import Welcome from './components/Welcome/Welcome';
import Dashboard from './components/Dashboard/Dashboard';
import Register from './components/auth/Register';
import SignIn from './components/auth/SignIn';
import { UserProvider, useUser } from './context/UserContext';
import ProtectedRoute from './helper/ProtectedRoute';
import CreateTree from './components/Dashboard/CreateTree';
import AboutUs from './components/Welcome/AboutUs';
import Contact from './components/Welcome/Contact';
import UserStories from './components/Welcome/UserStories';
import AIDrivenFamilyDiscovery from './components/Welcome/services/AIDrivenFamilyDiscovery';
import DNATestingAndMatching from './components/Welcome/services/DNATestingAndMatching';
import FamilyTreeBuilding from './components/Welcome/services/FamilyTreeBuilding';
import IgitreeConnect from './components/Welcome/services/IgitreeConnect';
import { EditFamilyNodeProvider } from './context/EditFamilyNodeContext';
import ForgetPasswordForm from './components/auth/ForgetPasswordForm';
import ResetPasswordForm from './components/auth/ResetPasswordForm';



const router = createBrowserRouter([
    {
        path: '/',
        element: <Welcome
            page="Home"
            keywords="sample, keywords"
            description="Sample description"
            twitter="sampleTwitterHandle"
            services={[]}
        />
    },
    {
        path: '/dashboard',
        element: <ProtectedRoute>
            <Dashboard
                page="DashBoard"
                keywords="sample, keywords"
                description="Sample description"
                twitter="sampleTwitterHandle"
                services={[]}
                notifications={[]}

            />
        </ProtectedRoute>,
    },
    {
        path: '/register',
        element: <Register />,
    },
    {
        path: '/login',
        element: <SignIn />,
    },
    {
        path: '/newtree',
        element: <CreateTree pageTitle={''} />,
    },
    {
        path: '/tree/:board_id',
        element: <App />,
    },

    {
        path: '/ai-driven-family-discovery',
        element:  <AIDrivenFamilyDiscovery

    />},

    {
        path: '/dna-testing-and-matching',
        element:  <DNATestingAndMatching

    />},
    {
        path: '/family-tree-build',
        element:  <FamilyTreeBuilding

    />}, 

   {
    path: '/igitree-connect',
    element: <IgitreeConnect
    />},

 {
        path: '/about',
        element:  <AboutUs

    />},
    {
        path: '/contacts',
        element:  <Contact

    />},
    {
        path: '/user-stories',
        element:  <UserStories

    />}, 
    {
        path: '/forgot',
        element:  <ForgetPasswordForm

    />},
    {
        path: '/reset-password',
        element:  <ResetPasswordForm

    />},
]);

createRoot(document.getElementById('root')!).render(
    <UserProvider>
        <EditFamilyNodeProvider>
        <RouterProvider router={router} />
        </EditFamilyNodeProvider>
    </UserProvider>
);














