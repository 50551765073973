import { ReactNode } from "react";

interface MessageProps {
  children: ReactNode;
}

export const Error = ({ children }: MessageProps) => (
  <div className="border-start border-danger bg-light text-dark small mt-3 py-2 px-3 rounded">
    <p className="mb-0">{children}</p>
  </div>
);

export const Success = ({ children }: MessageProps) => (
  <div className="border-start border-success bg-light text-dark small mt-3 py-2 px-3 rounded">
    <p className="mb-0">{children}</p>
  </div>
);
