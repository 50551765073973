import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import css from './EditFamilyNode.module.css';
import { useParams } from 'react-router-dom';
import API from '../../api/api';
import { EditFamilyNodeContext } from '../../context/EditFamilyNodeContext';

interface FormData {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    dateOfBirth: string;
    city: string;
    gender: string;
}

interface EditFamilyNodeProps {
    memberId: string;
    familyTreeId: string;
    onClose: () => void;
    onSave: () => void;
}

const EditFamilyNode: React.FC<EditFamilyNodeProps> = ({ memberId, familyTreeId, onClose, onSave }) => {
    const context = useContext(EditFamilyNodeContext);
    // const {currentFamilyTreeId} = context;
    console.log("Context", context);

    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        dateOfBirth: '',
        city: '',
        gender: '',
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const fetchMemberData = async () => {
        setLoading(true);
        try {
            const token = JSON.parse(localStorage.getItem("token") || '""');
            const response = await API.get(`/family_tree/${context?.currentFamilyTreeId}/member/${memberId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setFormData(response.data.member
            );
            console.log("response.data", response.data);
        } catch (err) {
            setError('Failed to fetch member data.');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (memberId) {
            fetchMemberData();
        }
    }, [memberId]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const token = JSON.parse(localStorage.getItem("token") || '""');
            await API.put(`/family_tree/${context?.currentFamilyTreeId}/member/${memberId}`,formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            onSave();
            window.location.reload();
        } catch (err) {
            setError('Failed to fetch member data.');
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className={css.overlay}>
            <div className={css.popup}>
                {loading && <p>Loading...</p>}
                {error && <p className={css.error}>{error}</p>}
                <form onSubmit={handleFormSubmit}>
                    <h2>Edit Member</h2>
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <input
                        type="date"
                        name="dateOfBirth"
                        placeholder="Date of Birth"
                        value={formData.dateOfBirth}
                        onChange={handleInputChange}
                    />
                    <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={formData.city}
                        onChange={handleInputChange}
                    />
                    <select
                        name="gender"
                        value={formData.gender}
                        required
                        onChange={handleInputChange}
                    >
                        <option value="" disabled>
                            Select Gender
                        </option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <button type="submit">{loading ? 'Loading...' : 'Submit'}</button>
                    <button type="button" onClick={onClose}>
                        Cancel
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditFamilyNode;
