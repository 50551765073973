// ImageSlider.tsx
import React from 'react';
import Slider from 'react-slick';

// Import carousel images
import MemorialMuseumImage from '../../assets/img/memorial-museum.jpg';
import FamilyMeetImage from '../../assets/img/family-meet.jpg';
import RwandaTreeImage from '../../assets/img/rwanda-tree.jpg';
import OldRwandaImage from '../../assets/img/old-rwanda.jpg';

// Import slick carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };

  return (
    <div className="image-slider">
      <Slider {...settings}>
        <div>
          <img className="img-fluid" src={MemorialMuseumImage} alt="iGiTREE Memorial Museum" />
        </div>
        <div>
          <img className="img-fluid" src={FamilyMeetImage} alt="iGiTREE Family Meet" />
        </div>
        <div>
          <img className="img-fluid" src={RwandaTreeImage} alt="iGiTREE Rwanda Tree" />
        </div>
        <div>
          <img className="img-fluid" src={OldRwandaImage} alt="iGiTREE Old Rwanda" />
        </div>
      </Slider>
    </div>
  );
};

export default ImageSlider;
